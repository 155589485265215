import {Link, useLocation} from '@remix-run/react'
import {ChevronRightIcon} from '~/components/icons'

export function FooterCTA() {
  const {pathname} = useLocation()
  if (['help', 'about'].includes(pathname.replace('/', ''))) {
    return null
  }

  return (
    <div className="border-t border-radix-mauve4 bg-gradient-to-b from-radix-mauve2 to-radix-mauve-black py-32">
      <div className="mx-4 flex max-w-7xl flex-col items-start gap-8 sm:mx-auto sm:flex-row sm:items-center">
        <div className="text-left font-display text-3xl font-bold tracking-tight text-radix-mauve12 sm:text-left sm:text-4xl">
          Your builds have never been this quick.
        </div>

        <Link
          to="/start"
          className="flex items-center gap-2 whitespace-nowrap rounded-lg border border-radix-grass7 bg-radix-grass3 px-4 py-3 text-sm font-bold leading-none text-radix-grass11 hover:border-radix-grass8"
        >
          <span>Start building</span>
          <ChevronRightIcon className="" />
        </Link>
      </div>
    </div>
  )
}
